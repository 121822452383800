// COLORS
$black: #2f2e2e;
$black-trans: #2f2e2e8b;
// $blue: #343D46;
$blue: #050507;
$blue-trans: #050507a9;
$white: #eee9e9;
$brown: #595959;
$brown2: #b99c64;
$dun: #EAE0D5;

// LAYOUT
$layoutPadding: 20px;
$pad: 10px;
$pad2: 20px;
$pad-small: 5px;
$pad-large: 30px;
$pad-xl: 60px;
$btn-pad: 12px 16px;
$gap: 10px;
$gap2: 20px;
$gap-large: 50px;
$margin: 50px;
$margin-xl: 100px;

// BREAKPOINTS
$mobile-breakpoint: 460px;
$tablet-breakpoint: 800px;
$desktop-breakpoint: 960px;
$wide-dekstop-breakpoint: 1600px;

// VARS

// TYPOGRAPHY
$smallest: 12px;
$smaller: 16px;
$small: 24px;
$small2: 20px;
$p: 30px;
$h1: 72px;
$h2: 60px;
$h3: 30px;
$h4: 22px;
$btn: 24px;
$in-radius: 12px;
$br: 12px;

$smaller-mobile: 12px;
$small-mobile: 16px;
$p-mobile: 24px;
$h1-mobile: 16vw;
$h2-mobile: 10vw;
$h3-mobile: 24px;
$h4-mobile: 18px;

$h1-tablet: 8vw;
$h2-tablet: 4vw;
$p-tablet: 20px;
