header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;

    .header {
        transition: 0.2s;
        background-color: $black-trans;
        color: $white;
        box-shadow: rgba(0, 0, 0, 0.19) 0px 5px 20px, rgba(0, 0, 0, 0.2) 0px 2px 10px;

        &.black {
            color: $white;
            background-color: $blue;

            nav {
                justify-content: space-between;
            }
        }
    }

    nav {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: $pad;
        gap: $gap2;

        @include for-mobile-layout {
            justify-content: space-between;
        }

        ul {
            width: fit-content;
            display: flex;
            align-items: center;
            justify-content: center;
            align-self: center;
            gap: $gap2;
            transition: 0.5s;

            @include for-mobile-layout {
                position: absolute;
                flex-direction: column;
                top: 88px;
                left: 0;
                background-color: $black;
                width: 100%;
                margin: -100% 0 0 0;
                z-index: -1;
                opacity: 0;
                padding: $pad2;

                &.is-active {
                    opacity: 1;
                    margin: 0;
                }
            }

            li {
                font-size: $small;

                a {}
            }
        }

        .hamburger {
            display: none !important;
            all: unset;
            cursor: pointer;

            @include for-mobile-layout {
                display: block !important;
            }

            .hamburger-inner,
            ::after,
            ::before {
                background-color: $white;
            }

            &.is-active {


                .hamburger-inner,
                ::after,
                ::before {
                    background-color: $white !important;
                }
            }
        }
    }
}