* {
    box-sizing: border-box;
}

html {
    width: 100%;
    background-color: $blue;
    color: $white;
    font-family: Satoshi;
    overflow-x: hidden;
}

body {
    margin: 0;
    ::selection {}
}

p,
a,
label {
    font-size: $p;
    line-height: $p;
}

h1 {
    font-size: $h1;
    line-height: $h1;
}

h2 {
    font-size: $h2;
    line-height: $h2;

}

h3 {
    font-size: $h3;
    line-height: $h3;

}

h4 {
    font-size: $h4;
    line-height: $h4;
}

// @include for-wider-layout {
//     h1 {
//         font-size: $h1-tablet;
//         line-height: $h1-tablet;
//     }

//     h2 {
//         font-size: $h2-tablet;
//         line-height: $h2-tablet;
//     }
// }

// @include for-mobile-layout {
//     h1 {
//         font-size: $h1-mobile;
//         line-height: $h1-mobile;
//     }

//     h2 {
//         font-size: $h2-mobile;
//         line-height: $h2-mobile;
//     }

//     h3 {
//         font-size: $h3-mobile;
//         line-height: $h3-mobile;
//     }

//     h4 {
//         font-size: $h4-mobile;
//         line-height: $h4-mobile;
//     }

//     p,
//     a,
//     label {
//         font-size: $p-mobile;
//     }

//     a {
//         font-size: $small-mobile !important;
//     }

// }
.logo {
    width: 100%;
    height: 100%;
    max-width: 100px;
    padding: 10px;
    background-color: $black;
    mask-size: 100%;
    -webkit-mask: url('../../images/logos/geva\ logo.svg') no-repeat center;
    mask: url('../../images/logos/geva\ logo.svg') no-repeat center;
    filter: invert(85%) sepia(7%) saturate(75%) hue-rotate(319deg) brightness(106%) contrast(100%);
}

// .black {
//     .logo {
//     }
// }

p,
h1,
h2,
h3,
h4,
h5 {
    margin: 0;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: Satoshi-bold;
}


ul,
ol {
    padding: 0;
    margin: 0;
    list-style: none;
}

::-webkit-scrollbar {
    width: 10px;
}

a {
    all: unset;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
        font-weight: 700;
        font-size: $p;
    }

    &.active {
        color: $white;
        font-weight: 700;
        font-family: Satoshi-Italic;
    }
}

.page {
    margin-top: 88px;
}

button,
a:not(header a) {
    all: unset;
    background-color: $brown;
    color: $white;
    width: fit-content;
    padding: $btn-pad;
    font-family: Satoshi-bold;
    font-size: $btn;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
        font-size: $btn;
        background-color: $white;
        color: $brown;
    }

    &:active {
        box-shadow: inset 10px 10px 21px #cac6c6,
            inset -10px -10px 21px #ffffff;
    }
}

::-webkit-scrollbar-thumb {
    background-color: $brown;

}

::-moz-selection {
    color: $black;
    background: $white;
}

::selection {
    color: $black;
    background: $white;
}