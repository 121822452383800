.game-audio {

    .main-games {
        display: flex;
        padding: $pad-large 0;
        flex-direction: column;

        .main-game {
            display: flex;
            width: 100%;
            padding: $pad2;
            gap: $gap-large;
            max-height: 500px;

            p {
                font-size: $small;
            }

            .text {
                display: flex;
                flex-direction: column;
                gap: $gap2;
                justify-content: space-between;

                span {
                    color: $brown;
                    font-size: $small2;
                }

                a {}
            }

            .plyr--video {
                box-shadow: 0px 0px 20px 10px rgba(94, 94, 94, 0.301);
                width: 100%;
            }

            .plyr {
                width: 100%;

                .plyr__poster {
                    background-size: cover;
                }
            }

            video {
                object-fit: cover;
            }

            .audio {
                max-height: 100%;
                height: fit-content;
                width: 60%;
                align-self: center;
                overflow: auto;
                border-radius: $br;
                overflow-x: hidden;

                .plyr {
                    border-radius: 0;
                    width: 100%;
                }
            }
        }
    }

    .games-fx {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .game-fx {
            background-color: $white;
            display: flex;
            width: 100%;
            padding: $pad2;
            min-height: 400px;
            align-items: center;
            flex: 33.333%;
            gap: $gap2;
            justify-content: center;

            .text {
                display: flex;
                flex-direction: column;
                gap: $gap;
                width: 70%;
                // height: 20%;
                background-color: rgba(0, 0, 0, 0.4);
                padding: $pad;
                backdrop-filter: blur(2px);
                align-items: center;
                justify-content: center;
                box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;

                h3 {
                    // height: 100%;
                }

                p {
                    font-size: $small2;
                    text-align: center;
                }

            }



            &.solitaire {
                background-image: url('../../images/games/solitaire.jpg');
                background-size: cover;
                background-position: center;
            }

            &.mikdash {
                background-image: url('../../images/games/mikdash.webp');
                background-size: fill;
                background-position: center;
            }

            &.mikball {
                background-image: url('../../images/games/mikball.webp');
                background-size: cover;
                background-position: center;
            }

            &.livin {
                background-image: url('../../images/games/livin.webp');
                background-size: cover;
                background-position: center;
            }

            &.mikspike {
                background-image: url('../../images/games/mikspike.webp');
                background-size: cover;
                background-position: center;
            }

            &.mikcafe {
                background-image: url('../../images/games/mikcafe.webp');
                background-size: cover;
                background-position: center;
            }

            &.mikwitch {
                background-image: url('../../images/games/mikwitch.jpg');
                background-size: cover;
                background-position: center;
            }
        }
    }


    .audio {
        width: 100%;
        display: flex;
        flex-direction: column;
        box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
        overflow-y: scroll;
        overflow-x: hidden;
        max-height: 250px;
        background-color: $white;
        border-radius: $br;

        &::-webkit-scrollbar-thumb {
            background-color: $brown2;
            border-radius: $br;
        }

        &::-webkit-scrollbar-track {
            border-radius: $br;
        }

        &::-webkit-scrollbar {
            width: 7px;
        }

        .plyr {
            border-radius: 0;
            width: 100%;
        }

        .player-wrapper {
            // align-items: center;
            width: 100%;
            position: relative;

            p {
                color: $black;
                font-size: $smaller;
                text-wrap: nowrap;
                background-color: transparent;
                padding: 0 $pad;
                position: absolute;
                top: 0;
                z-index: 1;
                left: 50%;
                transform: translateX(-50%);
            }

            input {
                margin-top: 10px;
            }
        }

    }
}