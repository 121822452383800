.swiper {
    width: 100%;

    .swiper-pagination {
        .swiper-pagination-bullet {
            background: $white;
            width: 40px;
            height: 10px;
            border-radius: $br;
        }

        .swiper-pagination-bullet-active {
            background: $brown2;
        }
    }

    .swiper-slide-active {
        // width: 80%;
    }

    .swiper-slide-next {}


}
