    .hero {
        display: flex;
        flex-direction: column;
        height: 100vh;
        // justify-content: center;
        position: relative;

        video {
            width: 100%;
            height: 100%;
            position: absolute;
            object-fit: cover;
        }

        .hero-content {
            position: absolute;
            display: flex;
            flex-direction: column;
            width: max-content;
            padding: $pad2;
            background-color: $blue-trans;
            border-radius: 0 20px 20px 0;
            left: 0;
            bottom: 15%;

            h2,
            h1 {
                color: $white;
                padding: $pad;
            }

            h2 {
                font-size: $h2 - 10;
            }
        }
    }