.home {
    display: flex;
    flex-direction: column;

    .home-section {
        display: flex;
        gap: $gap2;
        // justify-content: space-between;
        width: 100%;
        margin: $margin 0;

        .video {
            width: 100%;
            align-self: center;
            min-width: 50%;
        }

        .text {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        a {
            align-self: flex-end;
        }
    }

    .mid {
        background-color: $black;
        // color: $black;

        a {
            align-self: flex-start;
            background-color: $blue;
            color: $white;

            &:hover {
                font-size: $btn;
                background-color: $white;
                color: $brown;
            }

            &:active {
                box-shadow: inset 10px 10px 21px #cac6c6,
                    inset -10px -10px 21px #ffffff;
            }
        }
    }
}